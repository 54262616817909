import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import { AppWrap, MotionWrap } from "../../wrapper";
// import {images} from '../../constants';
import { urlFor, client } from "../../client";
import "./About.scss";
import Typed from "typed.js";
// import Typed from "https://unpkg.com/typed.js@2.0.16/dist/typed.umd.js";

// const abouts = [
//   { title: 'Web Development', description: ' I am a good developer.', imgUrl: images.about01},
//   { title: 'Frontend Development', description: 'I am a good developer.', imgUrl: images.about02},
//   { title: 'Backend Development', description: ' I am a good developer.', imgUrl: images.about03},
//   { title: 'MERN Stack Development', description: ' I am a good developer.', imgUrl: images.about04},
// ];

// const typed = new Typed('.typing', {
//   strings: ['ajaya', 'akash'],
//   typeSpeed: 30,
//   backSpeed: 50,
//   loop: true
//   });

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const typed = new Typed(".typing", {
      strings: ["evelopment", "esign"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 900,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      // cursorChar: "!",
      cursorChar: "",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const query = '*[_type == "abouts" ] ';
    client.fetch(query).then((data) => setAbouts(data));
  }, []);

  return (
    <>
      <h2 className="head-text">
        I Know That <span />
        <span>
          Good <span className="cltag">&lt;</span>D
          <span className="typing"></span>
          <span className="cltag">/&gt;</span>
        </span>
        <br />
        means <span>Good Business </span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            {/* <img src={ about.imgUrl} alt={about.title} /> */}
            {/* here we use sanity function instead of above line */}
            <img src={urlFor(about.imgUrl)} alt={about.title} />

            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {" "}
              {about.title}{" "}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {" "}
              {about.description}{" "}
            </p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
